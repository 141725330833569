/* custom-select-styles.css */

/* Apply Bootstrap styles to the control container */
.custom-select-container {
    height: 46px;
    line-height: 30px;
    background: #fff;
    border: 1px solid #f1f1f1;
    font-size: 14px;
    color: #324253;
    border-radius: 5px;
    box-shadow: none;
}

/* Apply Bootstrap styles to the input field */
.custom-select-container .react-select__input input {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 1rem;
    line-height: 1.5;
    height: auto;
}

/* Apply Bootstrap styles to the dropdown menu */
.custom-select-container .react-select__menu {
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    margin-top: 2px;
}

.dark .custom-select-container {
    color: #000000;
    /* Set text color to black */
    background: #000000;
    background-color: black;
    /* Set background color to black */
    border-color: #000000;

    /* Set border color to black */
}

.dark .css-13cymwt-control {
    color: #EFF0F1;
    background: #222222;
    border-color: #252525;

}

.dark .css-t3ipsp-control {
    color: #EFF0F1;
    background-color: black;
}

.dark .css-1dimb5e-singleValue {
    color: #EFF0F1;
}

.dark css-1xc3v61-indicatorContainer {
    background: #222222;
}

.dark .css-166bipr-Input{
    color: #EFF0F1;
}
.dark .css-1cfo1cf{
    color: #EFF0F1;
}

/* Apply Bootstrap styles to the input field */
.dark .custom-select-container .react-select__input input {
    border: 1px solid #000000;
    /* Set border color to black */
    background: #000000;
    /* Set background color to black */
    color: #EFF0F1;
    /* Set text color to white (or your desired text color) */
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 1rem;
    line-height: 1.5;
    height: auto;
}

/* Apply Bootstrap styles to the dropdown menu */
.dark .custom-select-container .react-select__menu {
    border: 1px solid #000000;
    /* Set border color to black */
    background: #000000;
    /* Set background color to black */
    border-radius: 4px;
    margin-top: 2px;
}

form .form-control {
    border: 1.5px solid #d6d0d0;
    box-shadow: black !important;
    border-radius: 1px;
}
.dark .form-control {
    
    border-radius: 1px;
}

/* .css-1cfo1cf */