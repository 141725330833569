.color-picker-container {
    position: relative;
}

.color-picker-popover {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000; /* Adjust this value as needed */
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
}
