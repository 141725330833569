.iq-sidebar-menu .side-menu li a {
    font-size: 15px !important;
    white-space: nowrap;
    display: block;
    color: #8F9FBC;
    position: relative;
    padding: 0.625rem 0.938rem;
    line-height: 18px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-transform: capitalize;
    transition: all 400ms;
    font-weight: 700;
}