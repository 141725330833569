/* Style for the custom date picker */
.custom-datepicker {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  color: #333;
  height: 47px;
}

/* Style for the custom select */
.custom-select {
  border: 2px solid black;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  /* Background color of the select */
  cursor: pointer;
}


.custom-select-container-dashboard {
  border: 1px solid black;
  border-radius: 4px;
}

/* Add more styles as needed */